<template>
  <div>
    <div class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Scheduling Tasks</h3>
          <div class="flex gap-8 align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <select v-model="trading" aria-label="trading" class="form-control" @change="searchByTrading()">
              <option value="">All Trading</option>
              <option value="1">Trading Activities</option>
              <option value="0">Non Trading Activities</option>
            </select>
            <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..." type="text"
              ref="searchInput" @keyup="search($event)" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="!isLoading && meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div v-if="!isLoading" class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>Merchant ID</th>
                <th>Merchant</th>
                <th>AltCoin</th>
                <th>SQL</th>
                <th>Status</th>
                <th>Out</th>
                <th>Trading</th>
                <th>PairID</th>
                <th>ErrorMessage</th>
                <th>Amount</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.ID }}</td>
                  <td class="no-wrap">
                    <router-link tag="a" :to="{ name: 'user-detail', params: { id: record.MerchantID } }">
                      {{ record.MerchantID }}
                    </router-link>
                  </td>
                  <td class="no-wrap">
                    <router-link tag="a"
                      :to="{ name: 'transaction-scheduling-tasks', params: { merchantID: record.MerchantID } }">
                      {{ record.ShopTitle + ' (' + record.OwnerNickName + ')' }}
                    </router-link>
                  </td>
                  <td class="no-wrap">
                    {{ record.CoinName + ' (' + record.LongName + ')' }}
                  </td>
                  <td style="min-width: 300px; word-break: break-all;">{{ record.SQL }}</td>
                  <td>
                    <span v-html="formatBoolean(record.Status)"></span>
                  </td>
                  <td>
                    <span v-html="formatBoolean(record.Out)"></span>
                  </td>
                  <td class="no-wrap">{{ record.Trading }}</td>
                  <td class="no-wrap">{{ record.PairID }}</td>
                  <td>{{ record.ErrorMessage }}</td>
                  <td>{{ record.Amount }}</td>
                  <td class="pr-16 no-wrap">
                    {{ formatDate(record.CreatedOn) }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="11">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="!isLoading && meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <va-loader v-if="isLoading"></va-loader>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification } from "@/lib/utils";
import VaLoader from "../components/partials/Loader";

export default {
  name: "TransactionSchedulingTasks",
  components: {
    VaLoader,
    Pagination,
  },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      trading: "",
      searching: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "jwtToken",
      "currentUser",
    ]),
  },
  watch: {
    $route(to, from) {
      if (to.params.merchantID !== from.params.merchantID) {
        if (to.params.merchantID) {
          this.keyword = to.params.merchantID;
          this.searchByMerchantID();
        }
      }
    },
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getPaginatedRecords(1, false);
          // remove merchant id filter from route if any
          if (this.$route.params.merchantID) {
            this.$router.replace({
              ...this.$router.currentRoute,
              params: {},
            });
          }
        }, 500);
      }
    },
    searchByMerchantID() {
      this.searching = "Searching...";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getPaginatedRecords(1, false);
      }, 500);
    },
    searchByTrading() {
      this.searching = "Searching...";
      this.getPaginatedRecords(1, false);
    },
    getPaginatedRecords(page = 1, isLoader = true) {
      if (isLoader) {
        this.isLoading = true;
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=transaction_scheduling_tasks`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword,
          trading: this.trading,
          limit: Number.parseInt(this.meta.limit),
        },
      }).then((response) => {
        this.isLoading = false;
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.isLoading = false;
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    formatBoolean(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    this.getPaginatedRecords();
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
